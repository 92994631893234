import React from 'react';
import './assets/css/construccion.scss';
import Circle from './assets/img/construccion/circle.svg'
import Elements from './assets/img/construccion/elements.svg'
import Points from './assets/img/construccion/points.svg'
import Popup from './assets/img/construccion/popup.svg'
import Logo from './assets/img/logos/logo.svg'
import Text from './assets/img/construccion/text2.png'


class Construccion extends React.Component{

  /* componentDidMount(){
    document.documentElement.requestFullscreen().catch((e) => {
      alert('fullscreen');
    })
  } */
  render(){
    return (
      <div className="conts_container">
          <div className="logo_container container">
            <img src={Logo} alt=""/>
          </div>
          <div className="text_container container">
            <img src={Text} alt=""/>
          </div>
          <div className="circle_container container">
            <img src={Circle} alt=""/>
          </div>
  
          <div className="elements_container container">
            <img src={Elements} alt="" className="all_elements"/>
            <img src={Popup} alt="" className="popup"/>
          </div>
          
          <div className="points_container container">
            <img src={Points} alt=""/>
          </div>
      </div>
    );
  }
}

export default Construccion;
